<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Imagens</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12">
          <form
            id="dropzone"
            action="/file-upload"
            class="form-control dropzone"
          >
            <div class="fallback">
              <input name="file" type="file" multiple />
            </div>
          </form>
        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">
        <soft-button
          type="button"
          color="secondary"
          variant="gradient"
          class="mb-0 js-btn-prev"
          title="Anterior"
          v-on:click="this.$parent.prevStep"
          >Anterior</soft-button
        >
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          v-on:click="this.$parent.nextStep"
          >Próximo</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/novocomponents/SoftButton.vue";
import Dropzone from "dropzone";

export default {
  name: "Media",
  components: {
    SoftButton,
  },
  async mounted() {
    Dropzone.autoDiscover = false;
    var drop = document.getElementById("dropzone");
    var token = await localStorage.getItem('token')
    const url = process.env.VUE_APP_API_BASE_URL
    let myDropzone = new Dropzone(drop, {

      url: url + "admin/produto/file",
      addRemoveLinks: true,
      headers:{
        'authorization':'Bearer ' + token
      },
      maxFiles:1
    });
    myDropzone.on("complete", (file) =>{
      if(file.xhr && file.xhr.responseText){
        console.log(this.$parent.produto)
        this.$parent.produto.imagens.push({
          "diretorio": file.xhr.responseText,
          "imagem_principal": true
        })
      }
    }).on("maxfilesexceeded",(file)=>{
      myDropzone.removeFile(file);
      alert('Limite excedido!')
      console.log(file,'maxfilesexceeded')
    })
    // {
    //         "diretorio": "finerdev/imagens_produtos/3ca56b3d3fb84642a95075c6ee85c485.avif",
    //         "imagem_principal": true,
    //         "materiais_imagem_id": 2
    //     }
  },
};
</script>
