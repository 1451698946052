<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Tamanhos</h5>
    <div class="multisteps-form__content">
      <div class="col-12 row" style="padding-top:0%">
        <div class="col-12" v-for="(horario,index) in $parent.produto.tamanhos" v-bind:key="index">
          <div class="row">
            <div class="col-12 col-sm-2">
              <label>Nome</label>
              <input
                class="multisteps-form__input form-control"
                type="text"
                v-model="$parent.produto.tamanhos[index].nome"
              />
            </div>
            <div class="col-12 col-sm-2">
              <label>Situação</label>
              <select
                id="choices-catalogo-ifood"
                class="form-control"
                name="choices-catalogo-ifood"
                v-model="$parent.produto.tamanhos[index].status"
              >
                <option v-bind:value="true" v-bind:selected="$parent.produto.tamanhos[index].status">Ativo</option>
                <option v-bind:value="false"  v-bind:selected="!$parent.produto.tamanhos[index].status">Inativo</option>
              </select>
            </div>
            <div class="col-12 col-sm-2">
              <label>Quantidade de Sabores</label>
              <input
                class="multisteps-form__input form-control"
                type="number"
                @blur="atualizaFracoes(index)"
                v-model="$parent.produto.tamanhos[index].quantidade_sabores"
              />
            </div>

            <div class="col-12 col-sm-1">
              <label>Fatias</label>
              <input
                class="multisteps-form__input form-control"
                type="number"
                v-model="$parent.produto.tamanhos[index].quantidade_fatias"
              />
            </div>

            <div class="col-2">
                <label>Delivery</label>
                <vue-numeric-input 
                    className="multisteps-form__input form-control"
                    :controls="false"
                    placeholder="99.00"
                    v-model="$parent.produto.tamanhos[index].valores[1].valor_desconto"
                    :precision="2"
                />
            </div>
            <div class="col-2">
                <label>Ifood</label>
                <vue-numeric-input 
                    className="multisteps-form__input form-control"
                    :controls="false"
                    placeholder="99.00"
                    v-model="$parent.produto.tamanhos[index].valores[2].valor_desconto"
                    :precision="2"
                />
            </div>
            <div class="col-12 col-sm-1"
                style="    padding-top: 4%;">
              <soft-button
                type="button"
                color="secondary"
                variant="gradient"
                class="mb-0 js-btn-prev"
                title="Prev"
                @click="$parent.removerTamanho(index)"
                ><i class="fa fa-minus"></i></soft-button
              >
            </div>
          </div>
        </div>
        <div class="mt-4 button-row d-flex col-12">
          <soft-button
              type="button"
              color="dark"
              variant="gradient"
              class="mb-0 ms-auto js-btn-next"
              title="Next"
              @click="this.$parent.adicionarTamanho"
              ><i class="fa fa-plus"></i></soft-button
            >
      </div>
      </div>
      <div class="row">
        <div class="mt-4 button-row d-flex col-12">
          <soft-button
            type="button"
            color="secondary"
            variant="gradient"
            class="mb-0 js-btn-prev"
            title="Prev"
            @click="this.$parent.prevStep"
            >Anterior</soft-button
          >
          <soft-button
            type="button"
            color="dark"
            variant="gradient"
            class="mb-0 ms-auto js-btn-next"
            title="Next"
            @click="this.$parent.nextStep"
            >Próximo</soft-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SoftInput from "@/novocomponents/SoftInput.vue";
import SoftButton from "@/novocomponents/SoftButton.vue";
export default {
  name: "Tamanhos",
  components: {
    // SoftInput,
    SoftButton,
  },
  async mounted() {
    console.log(this.$parent.produto)
  },
  methods:{
    async atualizaFracoes(index_tam){
        if(this.$parent.produto.tamanhos[index_tam].quantidade_sabores > 0){
            this.$parent.produto.tamanhos[index_tam].fracoes_aceitas = [];
            for (let index = 0; index < this.$parent.produto.tamanhos[index_tam].quantidade_sabores; index++) {
                this.$parent.produto.tamanhos[index_tam].fracoes_aceitas.push(index+1)
            }
        }else{
            this.$parent.produto.tamanhos[index_tam].fracoes_aceitas = []
        }
        console.log(this.$parent.produto.tamanhos[index_tam].fracoes_aceitas)
    }
  }
};
</script>
