<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Grupo de Adicionais</h5>
    <div class="multisteps-form__content">
      <div class="col-12 row" style="padding-top:0%">
        <div class="col-12" v-for="(complemento,index) in $parent.produto.complementos" v-bind:key="index">
          <div class="row">
            <div class="col-12 col-sm-2">
              <label>Nome</label>
              <input
                class="multisteps-form__input form-control"
                type="text"
                v-model="$parent.produto.complementos[index].nome"
              />
            </div>
            <div class="col-12 col-sm-1">
              <label>Min</label>
              <input
                class="multisteps-form__input form-control"
                type="number"
                min="0"
                v-model="$parent.produto.complementos[index].min"
              />
            </div>
            <div class="col-12 col-sm-1">
              <label>Max</label>
              <input
                class="multisteps-form__input form-control"
                type="number"
                min="0"
                v-model="$parent.produto.complementos[index].max"
              />
            </div>
            <div class="col-12 col-sm-2">
              <label>Por Quantidade</label>
              <select
                id="choices-catalogo-ifood"
                class="form-control"
                name="choices-catalogo-ifood"
                v-model="$parent.produto.complementos[index].por_quantidade"
              >
                <option v-bind:value="false" v-bind:selected="!$parent.produto.complementos[index].por_quantidade">Não</option>
                <option v-bind:value="true"  v-bind:selected="$parent.produto.complementos[index].por_quantidade">Sim</option>
              </select>
            </div>
            <div class="col-12 col-sm-2">
              <label>Situação</label>
              <select
                id="choices-catalogo-ifood"
                class="form-control"
                name="choices-catalogo-ifood"
                v-model="$parent.produto.complementos[index].status"
              >
                <option v-bind:value="true" v-bind:selected="$parent.produto.complementos[index].status">Ativo</option>
                <option v-bind:value="false"  v-bind:selected="!$parent.produto.complementos[index].status">Inativo</option>
              </select>
            </div>

            <div class="col-12 col-sm-3"
                style="    padding-top: 4%;">
              <button
                type="button"
                class="mx-1 mb-0 btn btn-outline-success btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#import"
                @click="setIndex(index)"
              >
                Complementos
              </button>
            </div>
            <div class="col-12 col-sm-1"
                style="    padding-top: 4%;">
              <soft-button
                type="button"
                color="secondary"
                variant="gradient"
                class="mb-0 js-btn-prev"
                title="Prev"
                @click="$parent.removerComplemento(index)"
                ><i class="fa fa-minus"></i></soft-button
              >
            </div>
          </div>
        </div>
        <div class="mt-4 button-row d-flex col-12">
          <soft-button
              type="button"
              color="dark"
              variant="gradient"
              class="mb-0 ms-auto js-btn-next"
              title="Next"
              @click="this.$parent.adicionarComplemento"
              ><i class="fa fa-plus"></i></soft-button
            >
      </div>
      </div>

      <div
        id="import"
        class="modal fade"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog mt-lg-10" style="    max-width: 80%;">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="ModalLabel" class="modal-title">
                Adicionais
              </h5>
              <i class="fas fa-upload ms-3"></i>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              
              <div class="col-12 row" style="padding-top:0%" v-if="$parent.produto.complementos[indexCom]">
                <div class="col-12" v-for="(complemento,index) in $parent.produto.complementos[indexCom].complementos" v-bind:key="index">
                  <div class="row">
                    <div class="col-12 col-sm-2">
                      <label>Nome</label>
                      <input
                        class="multisteps-form__input form-control"
                        type="text"
                        v-model="$parent.produto.complementos[indexCom].complementos[index].nome"
                      />
                    </div>
                    <div class="col-12 col-sm-2">
                      <label>Situação</label>
                      <select
                        id="choices-catalogo-ifood"
                        class="form-control"
                        name="choices-catalogo-ifood"
                        v-model="$parent.produto.complementos[indexCom].complementos[index].status"
                      >
                        <option v-bind:value="1" v-bind:selected="$parent.produto.complementos[indexCom].complementos[index].status == 1">Ativo</option>
                        <option v-bind:value="0"  v-bind:selected="$parent.produto.complementos[indexCom].complementos[index].status == 0">Inativo</option>
                      </select>
                    </div>

                    <div class="col-12 col-sm-2">
                      <label>Valor Delivery</label>
                      <vue-numeric-input 
                        className="multisteps-form__input form-control"
                        :controls="false"
                        placeholder="99.00"
                        v-model="$parent.produto.complementos[indexCom].complementos[index].valores[1].valor_desconto"
                        :precision="2"
                      />
                    </div>
                    <div class="col-12 col-sm-2">
                      <label>Valor Ifood</label>
                      <vue-numeric-input 
                        className="multisteps-form__input form-control"
                        :controls="false"
                        placeholder="99.00"
                        v-model="$parent.produto.complementos[indexCom].complementos[index].valores[2].valor_desconto"
                        :precision="2"
                      />
                    </div>

                    <div class="col-12 col-sm-1"
                        style="    padding-top: 3%;">
                      <soft-button
                        type="button"
                        color="secondary"
                        variant="gradient"
                        class="mb-0 js-btn-prev"
                        title="Prev"
                        @click="$parent.removerComplementos(indexCom,index)"
                        ><i class="fa fa-minus"></i></soft-button
                      >
                    </div>
                  </div>
                </div>
                <div class="mt-4 button-row d-flex col-12">
                  <soft-button
                      type="button"
                      color="dark"
                      variant="gradient"
                      class="mb-0 ms-auto js-btn-next"
                      title="Next"
                      @click="$parent.adicionarComplementoComplementos(indexCom)"
                      ><i class="fa fa-plus"></i></soft-button
                    >
              </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn bg-gradient-secondary btn-sm"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn bg-gradient-success btn-sm"
                data-bs-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mt-4 button-row d-flex col-12">
          <soft-button
            type="button"
            color="secondary"
            variant="gradient"
            class="mb-0 js-btn-prev"
            title="Prev"
            @click="this.$parent.prevStep"
            >Anterior</soft-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SoftInput from "@/novocomponents/SoftInput.vue";
// import setTooltip from "@/assets/js/tooltip.js";

	// import store from '@/store'
import SoftButton from "@/novocomponents/SoftButton.vue";
export default {
  name: "Adicionais",
  components: {
    // SoftInput,
    SoftButton
  },

  data: () => ({
    indexCom:0

  }),
  async mounted() {
    // setTooltip(store.produtos.bootstrap);

    console.log(this.$parent.produto)
  },

  methods: {
    setIndex(index) {
      this.indexCom = index
    }
  }
};
</script>
