<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Valores</h5>
    <div class="mt-3 multisteps-form__content">
      <div class="row">
        <div class="col-3">
          <label>Delivery</label>
          <vue-numeric-input 
            className="multisteps-form__input form-control"
            :controls="false"
            placeholder="99.00"
            v-model="$parent.produto.valores[1].valor_desconto"
            :precision="2"
          />
        </div>
        <div class="col-3">
          <label>Ifood</label>
          <vue-numeric-input 
            className="multisteps-form__input form-control"
            :controls="false"
            placeholder="99.00"
            v-model="$parent.produto.valores[2].valor_desconto"
            :precision="2"
          />
        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">
        <soft-button
          type="button"
          color="secondary"
          variant="gradient"
          class="mb-0 js-btn-prev"
          title="Prev"
          @click="this.$parent.prevStep"
          >Anterior</soft-button
        >
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          v-on:click="this.$parent.nextStep"
          >Próximo</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Choices from "choices.js";
import SoftButton from "@/novocomponents/SoftButton.vue";

export default {
  name: "Pricing",
  components: {
    SoftButton,
  },
  mounted() {
    if (document.getElementById("choices-currency")) {
      let element = document.getElementById("choices-currency");
      new Choices(element, {
        searchEnabled: false,
      });
    }

    if (document.getElementById("choices-tags")) {
      var tags = document.getElementById("choices-tags");
      const examples = new Choices(tags, {
        removeItemButton: true,
      });

      examples.setChoices(
        [
          {
            value: "One",
            label: "Expired",
            disabled: true,
          },
          {
            value: "Two",
            label: "Out of Stock",
            selected: true,
          },
        ],
        "value",
        "label",
        false
      );
    }
  },
};

</script>

<style>
  .numeric-input{
    display: block !important;
    width: 100% !important;
    padding: 18px 9px  !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.4rem !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #d2d6da !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border-radius: 0.5rem !important;
    transition: box-shadow 0.15s ease, border-color 0.15s ease !important;
  }
</style>