<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Horários</h5>
    <div class="multisteps-form__content">
      <div class="col-12 row" style="padding-top:0%">
        <div class="col-12" v-for="(horario,index) in $parent.produto.horario_disponivel" v-bind:key="index">
          <div class="row">
            <div class="col-12 col-sm-2">
              <label>De</label>
              <input
                class="multisteps-form__input form-control"
                type="time"
                v-model="$parent.produto.horario_disponivel[index].de"
              />
            </div>
            <div class="col-12 col-sm-2">
              <label>Até</label>
              <input
                class="multisteps-form__input form-control"
                type="time"
                v-model="$parent.produto.horario_disponivel[index].ate"
              />
            </div>
            <div class="col-12 col-sm-1">
              <label>Segunda</label>
              <select
                id="choices-catalogo-ifood"
                class="form-control"
                name="choices-catalogo-ifood"
                v-model="$parent.produto.horario_disponivel[index].segunda"
              >
                <option v-bind:value="false" v-bind:selected="!$parent.produto.horario_disponivel[index].segunda">Não</option>
                <option v-bind:value="true"  v-bind:selected="$parent.produto.horario_disponivel[index].segunda">Sim</option>
              </select>
            </div>
            <div class="col-12 col-sm-1">
              <label>Terça</label>
              <select
                id="choices-catalogo-ifood"
                class="form-control"
                name="choices-catalogo-ifood"
                v-model="$parent.produto.horario_disponivel[index].terca"
              >
                <option v-bind:value="false" v-bind:selected="!$parent.produto.horario_disponivel[index].terca">Não</option>
                <option v-bind:value="true"  v-bind:selected="$parent.produto.horario_disponivel[index].terca">Sim</option>
              </select>
            </div>
            <div class="col-12 col-sm-1">
              <label>Quarta</label>
              <select
                id="choices-catalogo-ifood"
                class="form-control"
                name="choices-catalogo-ifood"
                v-model="$parent.produto.horario_disponivel[index].quarta"
              >
                <option v-bind:value="false" v-bind:selected="!$parent.produto.horario_disponivel[index].quarta">Não</option>
                <option v-bind:value="true"  v-bind:selected="$parent.produto.horario_disponivel[index].quarta">Sim</option>
              </select>
            </div>
            <div class="col-12 col-sm-1">
              <label>Quinta</label>
              <select
                id="choices-catalogo-ifood"
                class="form-control"
                name="choices-catalogo-ifood"
                v-model="$parent.produto.horario_disponivel[index].quinta"
              >
                <option v-bind:value="false" v-bind:selected="!$parent.produto.horario_disponivel[index].quinta">Não</option>
                <option v-bind:value="true"  v-bind:selected="$parent.produto.horario_disponivel[index].quinta">Sim</option>
              </select>
            </div>
            <div class="col-12 col-sm-1">
              <label>Sexta</label>
              <select
                id="choices-catalogo-ifood"
                class="form-control"
                name="choices-catalogo-ifood"
                v-model="$parent.produto.horario_disponivel[index].sexta"
              >
                <option v-bind:value="false" v-bind:selected="!$parent.produto.horario_disponivel[index].sexta">Não</option>
                <option v-bind:value="true"  v-bind:selected="$parent.produto.horario_disponivel[index].sexta">Sim</option>
              </select>
            </div>
            <div class="col-12 col-sm-1">
              <label>Sabado</label>
              <select
                id="choices-catalogo-ifood"
                class="form-control"
                name="choices-catalogo-ifood"
                v-model="$parent.produto.horario_disponivel[index].sabado"
              >
                <option v-bind:value="false" v-bind:selected="!$parent.produto.horario_disponivel[index].sabado">Não</option>
                <option v-bind:value="true"  v-bind:selected="$parent.produto.horario_disponivel[index].sabado">Sim</option>
              </select>
            </div>
            <div class="col-12 col-sm-1">
              <label>Domingo</label>
              <select
                id="choices-catalogo-ifood"
                class="form-control"
                name="choices-catalogo-ifood"
                v-model="$parent.produto.horario_disponivel[index].domingo"
              >
                <option v-bind:value="false" v-bind:selected="!$parent.produto.horario_disponivel[index].domingo">Não</option>
                <option v-bind:value="true"  v-bind:selected="$parent.produto.horario_disponivel[index].domingo">Sim</option>
              </select>
            </div>
            <div class="col-12 col-sm-1"
                style="    padding-top: 4%;">
              <soft-button
                type="button"
                color="secondary"
                variant="gradient"
                class="mb-0 js-btn-prev"
                title="Prev"
                @click="$parent.removerHorario(index)"
                ><i class="fa fa-minus"></i></soft-button
              >
            </div>
          </div>
        </div>
        <div class="mt-4 button-row d-flex col-12">
          <soft-button
              type="button"
              color="dark"
              variant="gradient"
              class="mb-0 ms-auto js-btn-next"
              title="Next"
              @click="this.$parent.adicionarHorario"
              ><i class="fa fa-plus"></i></soft-button
            >
      </div>
      </div>
      <div class="row">
        <div class="mt-4 button-row d-flex col-12">
          <soft-button
            type="button"
            color="secondary"
            variant="gradient"
            class="mb-0 js-btn-prev"
            title="Prev"
            @click="this.$parent.prevStep"
            >Anterior</soft-button
          >
          <soft-button
            type="button"
            color="dark"
            variant="gradient"
            class="mb-0 ms-auto js-btn-next"
            title="Next"
            @click="this.$parent.nextStep"
            >Próximo</soft-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SoftInput from "@/novocomponents/SoftInput.vue";
import SoftButton from "@/novocomponents/SoftButton.vue";
export default {
  name: "Horarios",
  components: {
    // SoftInput,
    SoftButton,
  },
  async mounted() {
    console.log(this.$parent.produto)
  },
};
</script>
